import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useLocation,
} from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getCurrentUser, verifyToken } from "./services/authService";
import Home from "./components/project/Home";
import Missions from "./components/project/Missions";
import ProjectManagement from "./components/project/ProjectManagement";
import ActionTimer from "./components/common/ActionTimer";
import GoogleAuthButton from "./components/auth/GoogleAuthButton";
import ProfileEdit from "./components/Profile/ProfileComponent";
import TaskActionTimeList from "./components/project/TaskActionTimeList";
import ProjectTreeList from "./components/project/ProjectTreeList";
import { Box, CircularProgress } from "@mui/material";
import { getCurrentUserLevel } from "./services/authService";
import ClientManagement from "./components/project/ClientManagement";
import itemService from "./services/db/ItemService";
import SupplyChainManagement from "./components/project/SupplyChainManagement";
import BudgetManagement from "./components/project/BudgetManagement";
import OrderManagement from "./components/project/OrderManagement";
import JiraStyleKanban from "./components/project/JiraStyleKanban";
import { isPageRefresh, trackPageView, trackPageExit } from "./services/trackingService";
const ROUTES = {
  HOME: "/",
  PROJECT_TREE_LIST: "/projecttreelist",
  TASKS: "/tasks",
  TASK_ACTION_TIME_LIST: "/taskActionTimeList",
  KANBANS: "/kanbans",
  PROJECT_MANAGEMENT: "/projectmanagement",
  CLIENT_MANAGEMENT: "/clientmanagement",
  BUDGET_MANAGEMENT: "/budgetManagement",
  SUPPLY_CHAIN_MANAGEMENT: "/supplyChainManagement",
  ORDER_MANAGEMENT: "/orderManagement",
  PROFILE: "/profile",
};


function debounce(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <Router>
        <AppContent />
      </Router>
    </GoogleOAuthProvider>
  );
}

const LoadingIndicator = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    minHeight="100vh"
  >
    資料讀取中.....
    <CircularProgress />
  </Box>
);

const Header = ({
  isAuthenticated,
  handleAuthChange,
  user,
  setGoogleStatus,
}) => (
  <header className="bg-gray-800 text-white p-4 fixed top-0 left-0 right-0 z-50 shadow-lg">
    <div className="container mx-auto flex justify-between items-center flex-wrap">
      <h1 className="text-xl font-bold">專案管理系統</h1>
      <nav className="flex items-center space-x-4">
        {isAuthenticated && (
          <>
            <Link to={ROUTES.HOME} className="hover:underline">
              首頁
            </Link>
            <Link to={ROUTES.TASKS} className="hover:underline">
              任務
            </Link>
            <Link to={ROUTES.PROJECT_TREE_LIST} className="hover:underline">
              樹狀圖
            </Link>
            <Link to={ROUTES.BUDGET_MANAGEMENT} className="hover:underline">
              預算管理
            </Link>
            <Link to={ROUTES.TASK_ACTION_TIME_LIST} className="hover:underline">
              工時列表
            </Link>
            <Link to={ROUTES.KANBANS} className="hover:underline">
              看板
            </Link>
            {getCurrentUserLevel() > 100 && (
              <>
                <Link to={ROUTES.ORDER_MANAGEMENT} className="hover:underline">
                  物料訂單
                </Link>
                <Link to={ROUTES.CLIENT_MANAGEMENT} className="hover:underline">
                  公司管理
                </Link>
                <Link
                  to={ROUTES.SUPPLY_CHAIN_MANAGEMENT}
                  className="hover:underline"
                >
                  供應鏈管理
                </Link>
              </>
            )}
          </>
        )}
        <GoogleAuthButton
          isAuthenticated={isAuthenticated}
          onAuthChange={handleAuthChange}
          user={user}
          setStatus={setGoogleStatus}
        />
      </nav>
    </div>
  </header>
);

function AppContent(props) {
  const [editMode, setEditMode] = useState(() => {
    localStorage.setItem("editMode", "popup");
    return localStorage.getItem("editMode") || "popup";
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [googleStatus, setGoogleStatus] = useState(null);
  const [pageViewStartTime, setPageViewStartTime] = useState(null);
  const location = useLocation();

  const handleLogout = useCallback(() => {
    setIsAuthenticated(false);
    setUser(null);
    window.location.reload(true);
  }, []);

  useEffect(() => {
    const forceLogoutListener = () => {
      handleLogout();
    };

    itemService.on("forceLogout", forceLogoutListener);
    return () => itemService.off("forceLogout", forceLogoutListener);
  }, [handleLogout]);

  const handleAuthChange = useCallback(async (isAuth) => {
    setIsAuthenticated(isAuth);
    if (isAuth) {
      const currentUser = getCurrentUser();
      setUser(currentUser);
    } else {
      setUser(null);
    }
    setGoogleStatus(null);
  }, []);
  const handlePageView = useCallback(() => {
    if (isAuthenticated) {
      trackPageView(location.pathname, isPageRefresh());
    }
  }, [isAuthenticated, location.pathname]);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { isValid } = await verifyToken();
        handleAuthChange(isValid);
      } catch (error) {
        console.error("Authentication check failed:", error);
        handleAuthChange(false);
      } finally {
        setIsLoading(false);
      }
    };
    checkAuth();
  }, [handleAuthChange]);
  useEffect(() => {
    handlePageView();
  }, [handlePageView]);

  if (googleStatus) {
    return <LoadingIndicator />;
  }
  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="App flex flex-col min-h-screen">
      <Header
        isAuthenticated={isAuthenticated}
        toggleEditMode={setEditMode}
        editMode={editMode}
        handleAuthChange={handleAuthChange}
        user={user}
        setGoogleStatus={setGoogleStatus}
      />
      <div className="fixed top-16 right-4 z-50 w-auto max-w-2xl">
        <ActionTimer />
      </div>
      <main className="container mx-auto mt-20 flex-grow p-4 flex flex-col w-full max-w-full">
        <Routes>
          <Route
            path={ROUTES.HOME}
            element={
              isAuthenticated ? (
                <Home {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.KANBANS}
            element={
              isAuthenticated ? (
                <JiraStyleKanban {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.TASKS}
            element={
              isAuthenticated ? (
                <Missions {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.PROJECT_MANAGEMENT}
            element={
              isAuthenticated ? (
                <ProjectManagement {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.CLIENT_MANAGEMENT}
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <ClientManagement {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.BUDGET_MANAGEMENT}
            element={<BudgetManagement {...props} />}
          />
          <Route
            path={ROUTES.SUPPLY_CHAIN_MANAGEMENT}
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <SupplyChainManagement {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.ORDER_MANAGEMENT}
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <OrderManagement {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.TASK_ACTION_TIME_LIST}
            element={
              isAuthenticated ? (
                <TaskActionTimeList {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.PROJECT_TREE_LIST}
            element={
              isAuthenticated ? (
                <ProjectTreeList {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.PROFILE}
            element={
              isAuthenticated ? (
                <ProfileEdit {...props} />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
          <Route
            path={ROUTES.JIRA_STYLE_KANBAN}
            element={
              isAuthenticated && getCurrentUserLevel() > 100 ? (
                <JiraStyleKanban />
              ) : (
                <Navigate to={ROUTES.HOME} />
              )
            }
          />
        </Routes>
      </main>
      <footer className="bg-gray-800 text-white p-4">
        <div className="container mx-auto text-center">
          © 2024 毛多多股份有限公司. 版權所有.
        </div>
      </footer>
    </div>
  );
}

export default App;
