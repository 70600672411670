// services/trackingService.js
import { getUser } from "./authService";
import itemService from "./db/ItemService";

let lastPageViewTime = 0;
let lastTrackedPath = null;
let pageViewStartTime = 0;

export const isPageRefresh = () => {
  const currentTime = new Date().getTime();
  const isRefresh = currentTime - lastPageViewTime < 1000;
  lastPageViewTime = currentTime;
  return isRefresh;
};

export const trackPageView = (path, isRefresh = false) => {
  if (path === lastTrackedPath && !isRefresh) {
    return; // 避免重複追蹤相同路徑
  }
  
  const currentTime = new Date().getTime();
  
  // 如果有之前的頁面瀏覽記錄，先記錄 pageExit
  if (lastTrackedPath && pageViewStartTime) {
    const duration = (currentTime - pageViewStartTime) / 1000;
    if (duration >= 0.5) { // 只記錄停留時間超過 0.5 秒的頁面
      trackPageExit(lastTrackedPath, duration);
    }
  }
  
  const data = {
    type: isRefresh ? 'pageRefresh' : 'pageView',
    path,
    userId: getUser().userId
  };
  itemService.createItem("Tracking", data);
  
  lastTrackedPath = path;
  pageViewStartTime = currentTime;
};

export const trackPageExit = (path, duration) => {
  const data = {
    type: 'pageExit',
    path,
    userId: getUser().userId,
    duration: duration.toFixed(1)
  };
  itemService.createItem("Tracking", data);
};